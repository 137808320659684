<template>
  <div
    class="Base main-content"
    v-loading="loading"
    element-loading-text="正在保存，请稍等。。。"
    element-loading-spinner="el-icon-loading"
  >
    <div class="crt-content">
      <div v-show="!isReadOnly">
        <el-button
          v-if="isEdit"
          type="primary"
          class="common-button commonBtn"
          icon="el-icon-edit"
          @click="editClick"
        >
          编辑
        </el-button>
        <div v-else>
          <el-button
            type="primary"
            class="common-button commonBtn"
            icon="el-icon-edit"
            @click="closeClick"
          >
            取消
          </el-button>
          <el-button
            type="primary"
            class="common-button commonBtn"
            icon="el-icon-edit"
            @click="synchronousClick"
          >
            同步
          </el-button>
          <el-button
            type="success"
            class="common-button commonBtn"
            icon="el-icon-edit"
            @click="saveClick"
          >
            保存
          </el-button>
        </div>
      </div>
      <div>
        <el-button
          type="primary"
          class="common-button commonBtn"
          icon="el-icon-download"
          @click="followPlanDownload"
        >
          随访计划导出
        </el-button>
      </div>
    </div>
    <div class="body">
      <div class="input-form">
        <div class="input-data-left">
          <div class="data-form">
            <div v-for="(item, index) in showList" :key="index">
              <el-card
                class="data-form-item"
                v-if="item.group && item.group === 'PATIENT' && item.fields"
              >
                <el-divider content-position="left">病人信息</el-divider>
                <el-descriptions :column="columnNum" :colon="false">
                  <el-descriptions-item
                    label="库号:"
                    label-class-name="my-label"
                    v-if="item.fields['fileNumber'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.fileNumber">
                        {{ patient.fileNumber }}
                      </el-tag>
                    </div>
                    <el-input
                      class="input-form-item-content"
                      type="text"
                      v-model="patientInfo.fileNumber"
                      size="mini"
                      v-else
                    ></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="姓名:"
                    label-class-name="my-label"
                    v-if="item.fields['patientName'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.patientsName">
                        {{ patient.patientsName }}
                      </el-tag>
                    </div>
                    <el-input
                      id="patientName"
                      class="input-form-item-content"
                      placeholder="请输入"
                      type="text"
                      v-model="patientInfo.patientsName"
                      size="mini"
                      v-else
                    ></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="病案号:"
                    label-class-name="my-label"
                    v-if="item.fields['patientsID'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.patientsID">
                        {{ patient.patientsID }}
                      </el-tag>
                    </div>
                    <el-input
                      class="input-form-item-content"
                      type="text"
                      v-model="patientInfo.patientsID"
                      size="mini"
                      v-else
                    ></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="性别:"
                    label-class-name="my-label"
                    v-if="item.fields['patientSex'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.patientsSex">
                        {{ patient.patientsSex }}
                      </el-tag>
                    </div>
                    <cm-select
                      style="margin-top: 5px"
                      class="input-form-item-content"
                      v-model="patientInfo.patientsSex"
                      activeUrl="/v1/webconsole/comboData/get/ssdj_patientsSex"
                      v-else
                    ></cm-select>
                  </el-descriptions-item>

                  <el-descriptions-item
                    label="文化程度:"
                    label-class-name="my-label"
                    v-if="item.fields['eduLevel'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.eduLevel">{{ patient.eduLevel }}</el-tag>
                    </div>
                    <cm-select
                      style="margin-top: 5px"
                      class="input-form-item-content"
                      v-model="patientInfo.eduLevel"
                      activeUrl="/v1/webconsole/comboData/get/ssjbxx_whcd"
                      v-else
                    ></cm-select>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="年龄:"
                    label-class-name="my-label"
                    v-if="item.fields['patientAge'] === true"
                  >
                    <el-tag type="info" v-show="patient.patientsAge || patient.ageDays">
                      <span v-if="patient.ageDays">
                        {{ calculateAgeFromDays(patient.ageDays) }}
                      </span>
                      <span v-else>
                        {{ patient.patientsAge || '-' }}
                      </span>
                    </el-tag>
                  </el-descriptions-item>

                  <el-descriptions-item
                    label="证件号:"
                    label-class-name="my-label"
                    v-if="item.fields['patientsCertificate'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.patientsCertificate">
                        {{ patient.patientsCertificate }}
                      </el-tag>
                    </div>
                    <el-input
                      class="input-form-item-content"
                      type="text"
                      v-model="patientInfo.patientsCertificate"
                      size="mini"
                      v-else
                    ></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="本人电话:"
                    label-class-name="my-label"
                    v-if="item.fields['patientsPhone'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.patientsPhone">
                        {{ patient.patientsPhone }}
                      </el-tag>
                    </div>
                    <el-input
                      class="input-form-item-content"
                      type="text"
                      v-model="patientInfo.patientsPhone"
                      size="mini"
                      v-else
                    ></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="身高:"
                    label-class-name="my-label"
                    v-if="item.fields['height'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.height">{{ patient.height }}</el-tag>
                    </div>
                    <el-input
                      class="input-form-item-content"
                      type="text"
                      v-model="patientInfo.height"
                      size="mini"
                      v-else
                    >
                      <template slot="append">cm</template>
                    </el-input>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="体重:"
                    label-class-name="my-label"
                    v-if="item.fields['weight'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.weight">{{ patient.weight }}</el-tag>
                    </div>
                    <el-input
                      class="input-form-item-content"
                      type="text"
                      v-model="patientInfo.weight"
                      size="mini"
                      v-else
                    >
                      <template slot="append">kg</template>
                    </el-input>
                  </el-descriptions-item>

                  <el-descriptions-item
                    label="婚姻状况:"
                    label-class-name="my-label"
                    v-if="item.fields['marry'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.marry">
                        {{ patient.marry }}
                      </el-tag>
                    </div>
                    <cm-select
                      class="input-form-item-content"
                      v-model="patientInfo.marry"
                      activeUrl="/v1/webconsole/comboData/get/PUB_maritalStatus"
                      v-else
                    ></cm-select>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="国家:"
                    label-class-name="my-label"
                    v-if="item.fields['nation'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.nation">{{ patient.nation }}</el-tag>
                    </div>
                    <el-input
                      class="input-form-item-content"
                      placeholder="请输入国籍"
                      type="text"
                      v-model="patientInfo.nation"
                      size="mini"
                      v-else
                    ></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item
                    :span="columnNum"
                    label="省/市/县"
                    label-class-name="my-label"
                    v-if="item.fields['province'] === true"
                  >
                    <div class="pwrap">
                      <div v-if="isEdit">
                        <el-tag type="info" v-show="patient.province">
                          {{ patient.province }}
                          <span v-if="patient.city">/</span>
                          {{ patient.city }}
                          <span v-if="patient.county">/</span>
                          {{ patient.county }}
                        </el-tag>
                      </div>
                      <v-distpicker
                        :province="patientInfo.province"
                        :city="patientInfo.city"
                        :area="patientInfo.county"
                        @selected="onSelected"
                        style="
                          display: flex;
                          padding: 0;
                          height: 28px;
                          font-size: 14px;
                          line-height: 28px;
                        "
                        v-else
                      ></v-distpicker>
                      <div class="info-address">
                        <span>家庭住址：</span>
                        <div v-if="isEdit">
                          <el-tag type="info" v-show="patient.patientsAddress">
                            {{ patient.patientsAddress }}
                          </el-tag>
                        </div>
                        <el-input
                          class="input-form-item-content"
                          type="text"
                          v-model="patientInfo.patientsAddress"
                          size="mini"
                          v-else
                        ></el-input>
                      </div>
                    </div>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="联系人姓名:"
                    label-class-name="my-label"
                    v-if="item.fields['contactName'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.contactName">
                        {{ patient.contactName }}
                      </el-tag>
                    </div>
                    <el-input
                      class="input-form-item-content"
                      placeholder="请输入"
                      type="text"
                      v-model="patientInfo.contactName"
                      size="mini"
                      v-else
                    ></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="联系人手机号:"
                    label-class-name="my-label"
                    v-if="item.fields['contactPhone'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.contactPhone">
                        {{ patient.contactPhone }}
                      </el-tag>
                    </div>
                    <el-input
                      class="input-form-item-content"
                      type="text"
                      v-model="patientInfo.contactPhone"
                      size="mini"
                      v-else
                    ></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="民族:"
                    label-class-name="my-label"
                    v-if="item.fields['ethnicity'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.ethnicity">
                        {{ patient.ethnicity }}
                      </el-tag>
                    </div>
                    <el-input
                      class="input-form-item-content"
                      placeholder="请输入民族"
                      type="text"
                      v-model="patientInfo.ethnicity"
                      size="mini"
                      v-else
                    ></el-input>
                  </el-descriptions-item>

                  <el-descriptions-item
                    label="住院号:"
                    label-class-name="my-label"
                    v-if="item.fields['hisNo'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.inPatientNo">
                        {{ patient.inPatientNo }}
                      </el-tag>
                    </div>
                    <el-input
                      class="input-form-item-content"
                      type="text"
                      v-model="patientInfo.inPatientNo"
                      size="mini"
                      v-else
                    ></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="病区:"
                    label-class-name="my-label"
                    v-if="item.fields['ward'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.ward">{{ patient.ward }}</el-tag>
                    </div>
                    <el-input
                      class="input-form-item-content"
                      type="text"
                      v-model="patientInfo.ward"
                      size="mini"
                      v-else
                    ></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="床号:"
                    label-class-name="my-label"
                    v-if="item.fields['bedNo'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.bedNo">{{ patient.bedNo }}</el-tag>
                    </div>
                    <el-input
                      class="input-form-item-content"
                      type="text"
                      v-model="patientInfo.bedNo"
                      size="mini"
                      v-else
                    ></el-input>
                  </el-descriptions-item>

                  <el-descriptions-item
                    label="病人性质:"
                    label-class-name="my-label"
                    v-if="item.fields['insuranceType'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.insuranceType">
                        {{ patient.insuranceType }}
                      </el-tag>
                    </div>
                    <cm-select
                      class="input-form-item-content"
                      v-model="patientInfo.insuranceType"
                      activeUrl="/v1/webconsole/comboData/get/PUB_insuranceType"
                      v-else
                    ></cm-select>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="患者入院方式:"
                    label-class-name="my-label"
                    v-if="item.fields['admissionType'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.admissionType">
                        {{ patient.admissionType }}
                      </el-tag>
                    </div>
                    <cm-select
                      class="input-form-item-content"
                      v-model="patientInfo.admissionType"
                      activeUrl="/v1/webconsole/comboData/get/ssjbxx_AdmissionType"
                      v-else
                    ></cm-select>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="患者入院时间:"
                    label-class-name="my-label"
                    v-if="item.fields['admissionDate'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.admissionDate">
                        {{ patient.admissionDate }}
                      </el-tag>
                    </div>
                    <div class="data-picker" v-else>
                      <el-date-picker
                        v-model="patientInfo.admissionDate"
                        type="date"
                        align="center"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                      ></el-date-picker>
                    </div>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="患者出院时间:"
                    label-class-name="my-label"
                    v-if="item.fields['dischargeDate'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-show="patient.dischargeDate">
                        {{ patient.dischargeDate }}
                      </el-tag>
                    </div>
                    <div class="data-picker" v-else>
                      <el-date-picker
                        v-model="patientInfo.dischargeDate"
                        type="date"
                        align="center"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                      ></el-date-picker>
                    </div>
                  </el-descriptions-item>
                  <el-descriptions-item
                    v-if="item.fields['dischargeDate'] === true && columnNum === 4"
                  ></el-descriptions-item>
                  <el-descriptions-item
                    v-if="item.fields['dischargeDate'] === true && columnNum === 4"
                  ></el-descriptions-item>
                </el-descriptions>
              </el-card>
              <el-card
                class="data-form-item"
                v-else-if="item.group && item.group === 'BACKGROUND' && item.fields"
              >
                <el-divider content-position="left">背景资料</el-divider>
                <el-descriptions :column="columnNum" :colon="false">
                  <el-descriptions-item
                    label="STEMI:"
                    label-class-name="my-label"
                    v-if="item.fields['stemi'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-if="patient.stemi">是</el-tag>
                      <el-tag type="info" v-else>否</el-tag>
                    </div>
                    <el-radio-group v-else v-model="patientStemi" style="margin-top: 5px">
                      <el-radio label="是" @change="changeStemi">是</el-radio>
                      <el-radio label="否" @change="changeStemi">否</el-radio>
                    </el-radio-group>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="是否首次手术:"
                    label-class-name="my-label"
                    v-if="item.fields['isFirstOperation'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-if="patient.isFirstOperation == '是'">是</el-tag>
                      <el-tag type="info" v-else>否</el-tag>
                    </div>
                    <el-radio-group
                      v-else
                      v-model="patientInfo.isFirstOperation"
                      style="margin-top: 5px"
                    >
                      <el-radio label="是">是</el-radio>
                      <el-radio label="否">否</el-radio>
                    </el-radio-group>
                  </el-descriptions-item>
                  <el-descriptions-item
                    v-if="
                      item.fields['reOperationReason'] === true &&
                      patientInfo.isFirstOperation &&
                      patientInfo.isFirstOperation === '否'
                    "
                    label="再次手术原因:"
                    label-class-name="my-label"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-if="patient.reOperationReason">
                        {{ patient.reOperationReason }}
                      </el-tag>
                      <el-tag type="info" v-else>无</el-tag>
                    </div>
                    <el-input
                      class="input-form-item-content"
                      type="text"
                      v-model="patientInfo.reOperationReason"
                      size="mini"
                      v-else
                    ></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item
                    v-if="item.fields['reOperationReason'] === true && columnNum == 4"
                  ></el-descriptions-item>
                  <el-descriptions-item
                    label="HBS_Ag:"
                    label-class-name="my-label"
                    v-if="item.fields['hbsag'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-if="patient.hbsag">{{ patient.hbsag }}</el-tag>
                    </div>
                    <el-radio-group v-else v-model="patientInfo.hbsag" style="margin-top: 5px">
                      <el-radio label="阳性">阳性</el-radio>
                      <el-radio label="阴性">阴性</el-radio>
                    </el-radio-group>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="梅毒RPR:"
                    label-class-name="my-label"
                    v-if="item.fields['rpr'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-if="patient.rpr">{{ patient.rpr }}</el-tag>
                      <el-tag type="info" v-else>无</el-tag>
                    </div>
                    <el-radio-group v-else v-model="patientInfo.rpr" style="margin-top: 5px">
                      <el-radio label="阳性">阳性</el-radio>
                      <el-radio label="阴性">阴性</el-radio>
                    </el-radio-group>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="抗-HCV:"
                    label-class-name="my-label"
                    v-if="item.fields['hcv'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-if="patient.hcv">{{ patient.hcv }}</el-tag>
                      <el-tag type="info" v-else>无</el-tag>
                    </div>
                    <el-radio-group v-else v-model="patientInfo.hcv" style="margin-top: 5px">
                      <el-radio label="阳性">阳性</el-radio>
                      <el-radio label="阴性">阴性</el-radio>
                    </el-radio-group>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="抗-HIV:"
                    label-class-name="my-label"
                    v-if="item.fields['hiv'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-if="patient.hiv">{{ patient.hiv }}</el-tag>
                      <el-tag type="info" v-else>无</el-tag>
                    </div>
                    <el-radio-group v-else v-model="patientInfo.hiv" style="margin-top: 5px">
                      <el-radio label="阳性">阳性</el-radio>
                      <el-radio label="阴性">阴性</el-radio>
                    </el-radio-group>
                  </el-descriptions-item>
                  <el-descriptions-item
                    v-if="columnNum == 3 || columnNum == 2"
                  ></el-descriptions-item>
                  <!-- <el-descriptions-item v-if="columnNum == 3"></el-descriptions-item> -->
                  <el-descriptions-item
                    label="既往病史:"
                    label-class-name="my-label"
                    v-if="
                      item.fields['isDyslipidemia'] === true ||
                      item.fields['isHypertension'] === true ||
                      item.fields['isDiabetes'] === true ||
                      item.fields['isHeartDisease'] === true ||
                      item.fields['medicalHistoryNote'] === true
                    "
                  >
                    <div v-if="isEdit">
                      <div
                        type="info"
                        v-if="
                          patient.isDyslipidemia ||
                          patient.isHypertension ||
                          patient.isDiabetes ||
                          patient.isHeartDisease ||
                          patient.medicalHistoryNote
                        "
                      >
                        <el-tag
                          style="margin-right: 5px"
                          type="info"
                          v-if="patient.isDyslipidemia === '是'"
                        >
                          血脂异常
                        </el-tag>
                        <el-tag
                          style="margin-right: 5px"
                          type="info"
                          v-if="patient.isHypertension === '是'"
                        >
                          高血压
                        </el-tag>
                        <el-tag
                          style="margin-right: 5px"
                          type="info"
                          v-if="patient.isDiabetes === '是'"
                        >
                          糖尿病
                        </el-tag>
                        <el-tag
                          style="margin-right: 5px"
                          type="info"
                          v-if="patient.isHeartDisease === '是'"
                        >
                          心脏病
                        </el-tag>
                        <template v-if="patient.medicalHistoryNote">
                          <el-tag
                            v-for="(item, index) in patient.medicalHistoryNote?.split('|')"
                            :key="'Note_' + index"
                            style="margin-right: 5px"
                            type="info"
                          >
                            {{ item }}
                          </el-tag>
                        </template>
                      </div>
                      <el-tag type="info" v-else>无</el-tag>
                    </div>
                    <div v-else style="display: flex">
                      <el-radio-group v-model="historyNote" style="margin-top: 4px">
                        <el-radio label="无" @change="changeHistoryNote('wu')">无</el-radio>
                        <el-radio label="有" @change="changeHistoryNote('you')">有</el-radio>
                      </el-radio-group>
                      <span
                        v-show="historyNote && historyNote == '有'"
                        style="margin-left: 7px; margin-right: 7px"
                      >
                        (
                      </span>
                      <el-checkbox-group
                        v-show="historyNote && historyNote == '有'"
                        v-model="checkList"
                        @change="handleCheckedChange"
                      >
                        <el-checkbox label="血脂异常">血脂异常</el-checkbox>
                        <el-checkbox label="高血压">高血压</el-checkbox>
                        <el-checkbox label="糖尿病">糖尿病</el-checkbox>
                        <el-checkbox label="心脏病">心脏病</el-checkbox>
                        <el-checkbox label="其他">
                          其他：
                          <el-input
                            class="input-form-item-content"
                            type="text"
                            v-model="patientInfo.medicalHistoryNote"
                            size="mini"
                            :disabled="historyNote_status"
                          ></el-input>
                        </el-checkbox>
                      </el-checkbox-group>
                    </div>
                  </el-descriptions-item>
                </el-descriptions>
                <el-descriptions :column="columnNum" :colon="false">
                  <el-descriptions-item
                    label="药物过敏:"
                    label-class-name="my-label"
                    v-if="item.fields['drugAllergyNote'] === true"
                  >
                    <div v-if="isEdit">
                      <div v-if="patient.drugAllergyNote">
                        <el-tag
                          v-for="(item, index) in patient.drugAllergyNote?.split('|')"
                          :key="'drug_' + index"
                          style="margin-right: 5px"
                          type="info"
                        >
                          {{ item }}
                        </el-tag>
                      </div>
                      <el-tag type="info" v-else>无</el-tag>
                    </div>
                    <el-input
                      class="input-form-item-content"
                      type="text"
                      placeholder='多个药物过敏使用 "|" 分隔'
                      v-model="patientInfo.drugAllergyNote"
                      size="mini"
                      v-else
                    ></el-input>
                  </el-descriptions-item>
                </el-descriptions>
                <el-descriptions :column="columnNum" :colon="false">
                  <el-descriptions-item
                    label="是否吸烟:"
                    label-class-name="my-label"
                    v-if="item.fields['isSmoke'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-if="patient.isSmoke == '是'">是</el-tag>
                      <el-tag type="info" v-else>否</el-tag>
                    </div>
                    <el-radio-group v-else v-model="patientInfo.isSmoke" style="margin-top: 5px">
                      <el-radio label="是">是</el-radio>
                      <el-radio label="否">否</el-radio>
                    </el-radio-group>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="是否喝酒:"
                    label-class-name="my-label"
                    v-if="item.fields['isAlcohol'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-if="patient.isAlcohol == '是'">是</el-tag>
                      <el-tag type="info" v-else>否</el-tag>
                    </div>
                    <el-radio-group v-else v-model="patientInfo.isAlcohol" style="margin-top: 5px">
                      <el-radio label="是">是</el-radio>
                      <el-radio label="否">否</el-radio>
                    </el-radio-group>
                  </el-descriptions-item>
                </el-descriptions>
                <el-descriptions :column="columnNum" :colon="false">
                  <el-descriptions-item
                    label="重要事项:"
                    label-class-name="my-label"
                    v-if="item.fields['importantItem'] === true"
                  >
                    <div v-if="isEdit">
                      <el-tag type="info" v-if="patient.importantItem">
                        {{ patient.importantItem }}
                      </el-tag>
                      <el-tag type="info" v-else>无</el-tag>
                    </div>
                    <el-input
                      class="input-form-item-textContent"
                      type="textarea"
                      v-model="patientInfo.importantItem"
                      size="mini"
                      :rows="3"
                      placeholder="请输入"
                      v-else
                    ></el-input>
                  </el-descriptions-item>
                </el-descriptions>
              </el-card>
              <el-card
                class="data-form-item"
                v-else-if="item.group && item.group === 'OPERATION' && item.fields"
              >
                <el-divider content-position="left">手术信息</el-divider>
                <el-descriptions :column="columnNum" :colon="false">
                  <el-descriptions-item
                    label="申请科室:"
                    label-class-name="my-label"
                    v-if="item.fields['sickArea'] === true"
                  >
                    <el-tag type="info" v-show="patient.sickArea">{{ patient.sickArea }}</el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="申请时间:"
                    label-class-name="my-label"
                    v-if="item.fields['studyApplyTime'] === true"
                  >
                    <el-tag type="info" v-show="patient.studyApplyTime">
                      {{ patient.studyApplyTime }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="手术类型:"
                    label-class-name="my-label"
                    v-if="item.fields['examineType'] === true"
                  >
                    <el-tag type="info" v-show="patient.examineType">
                      {{ patient.examineType }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="拟手术时间:"
                    label-class-name="my-label"
                    v-if="item.fields['planStudyDate'] === true"
                  >
                    <el-tag type="info" v-show="patient.planStudyDate">
                      {{ patient.planStudyDate }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="手术时间:"
                    label-class-name="my-label"
                    v-if="item.fields['studyDate'] === true"
                  >
                    <el-tag type="info" v-show="patient.studyDate">
                      {{ patient.studyDate }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="手术开始时间:"
                    label-class-name="my-label"
                    v-if="item.fields['studyStartDate'] === true"
                  >
                    <el-tag type="info" v-show="patient.studyStartDate">
                      {{ patient.studyStartDate }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="手术结束时间:"
                    label-class-name="my-label"
                    v-if="item.fields['studyEndDate'] === true"
                  >
                    <el-tag type="info" v-show="patient.studyEndDate">
                      {{ patient.studyEndDate }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="术前诊断:"
                    label-class-name="my-label"
                    v-if="item.fields['preoperativeDiagnosis'] === true"
                  >
                    <el-tag
                      v-if="patient.preoperativeDiagnosis"
                      style="margin-right: 5px"
                      type="info"
                    >
                      {{ patient.preoperativeDiagnosis }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="临床诊断:"
                    label-class-name="my-label"
                    v-if="item.fields['clinicDiagnose'] === true"
                  >
                    <el-tag v-if="patient.clinicDiagnose" style="margin-right: 5px" type="info">
                      {{ patient.clinicDiagnose }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="手术类别:"
                    label-class-name="my-label"
                    v-if="item.fields['classification'] === true"
                  >
                    <el-tag type="info" v-show="patient.classification">
                      {{ patient.classification }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="切口类型:"
                    label-class-name="my-label"
                    v-if="item.fields['cutType'] === true"
                  >
                    <el-tag type="info" v-show="patient.cutType">{{ patient.cutType }}</el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="临床症状:"
                    label-class-name="my-label"
                    v-if="item.fields['clinicalSymptoms'] === true"
                  >
                    <el-tag type="info" v-show="patient.clinicalSymptoms">
                      {{ patient.clinicalSymptoms }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="合并症:"
                    label-class-name="my-label"
                    v-if="item.fields['complication'] === true"
                  >
                    <el-tag type="info" v-if="patient.complication">
                      {{ patient.complication }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="拟施手术:"
                    label-class-name="my-label"
                    v-if="item.fields['examineList'] === true"
                  >
                    {{ patient.examineNames }}
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="主刀医师:"
                    label-class-name="my-label"
                    v-if="item.fields['examineDoctor'] === true"
                  >
                    <el-tag type="info" v-if="patient.examineDoctor">
                      {{ patient.examineDoctor }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="一助:"
                    label-class-name="my-label"
                    v-if="item.fields['assistantDoctor1'] === true"
                  >
                    <el-tag type="info" v-if="patientInfo.assistantDoctor1">
                      {{ patientInfo.assistantDoctor1 }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="二助:"
                    label-class-name="my-label"
                    v-if="item.fields['assistantDoctor2'] === true"
                  >
                    <el-tag type="info" v-if="patient.assistantDoctor2">
                      {{ patient.assistantDoctor2 }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="三助:"
                    label-class-name="my-label"
                    v-if="item.fields['assistantDoctor3'] === true"
                  >
                    <el-tag type="info" v-if="patient.assistantDoctor3">
                      {{ patient.assistantDoctor3 }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="护士:"
                    label-class-name="my-label"
                    v-if="item.fields['scrubNurse'] === true"
                  >
                    <el-tag type="info" v-if="patient.nurses">{{ patient.nurses }}</el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="技师:"
                    label-class-name="my-label"
                    v-if="item.fields['tech'] === true"
                  >
                    <el-tag type="info" v-if="patient.techs">{{ patient.techs }}</el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="巡回:"
                    label-class-name="my-label"
                    v-if="item.fields['tour'] === true"
                  >
                    <el-tag type="info" v-if="patient.tour">{{ patient.tour }}</el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="麻醉会诊:"
                    label-class-name="my-label"
                    v-if="item.fields['anesthesiaConsultation'] === true"
                  >
                    <el-tag type="info" v-show="patient.anesthesiaConsultation">
                      {{ patient.anesthesiaConsultation }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="麻醉方法:"
                    label-class-name="my-label"
                    v-if="item.fields['anesthesiaMethod'] === true"
                  >
                    <el-tag type="info" v-show="patient.anesthesiaMethod">
                      {{ patient.anesthesiaMethod }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="麻醉医生:"
                    label-class-name="my-label"
                    v-if="item.fields['anesthesiaDoctor'] === true"
                  >
                    <el-tag type="info" v-if="patient.anesthesiaDoctors">
                      {{ patient.anesthesiaDoctors }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="记录人:"
                    label-class-name="my-label"
                    v-if="item.fields['recordName'] === true"
                  >
                    <el-tag type="info" v-if="patient.recordName">
                      {{ patient.recordName }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="记录时间:"
                    label-class-name="my-label"
                    v-if="item.fields['recordDate'] === true"
                  >
                    <el-tag type="info" v-if="patient.recordDate">
                      {{ patient.recordDate }}
                    </el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label="预计时长:"
                    label-class-name="my-label"
                    v-if="item.fields['planStudyDuration'] === true"
                  >
                    <el-tag type="info" v-show="patient.planStudyDuration">
                      {{ patient.planStudyDuration }}
                    </el-tag>
                  </el-descriptions-item>
                </el-descriptions>
              </el-card>
            </div>
          </div>
        </div>
        <el-card class="input-data-right" v-if="offSideShow">
          <el-divider content-position="left">手术相关信息</el-divider>
          <div class="input-data-right-bottom">
            <template v-for="(item, index) in showList">
              <div
                :key="index"
                v-if="
                  item.group &&
                  item.group === 'OFFSIDE' &&
                  item.fields &&
                  item.fields['relatedInformation'] === true
                "
              >
                <div class="item-inline" v-if="item.fields['lab'] === true">
                  <span>手术室:</span>
                  <el-tag type="info" v-if="patient.lab">{{ patient.lab }}</el-tag>
                </div>
                <div class="item-inline" v-if="item.fields['operateNumberTitle'] === true">
                  <span>台次:</span>
                  <el-tag type="info" v-if="patient.operateNumberTitle">
                    {{ patient.operateNumberTitle }}
                  </el-tag>
                </div>
                <div class="item-inline" v-if="item.fields['examineDoctor'] === true">
                  <span>主刀医师:</span>
                  <el-tag type="info" v-if="patient.examineDoctor">
                    {{ patient.examineDoctor }}
                  </el-tag>
                </div>
                <div class="item-inline" v-if="item.fields['sickArea'] === true">
                  <span>申请科室:</span>
                  <el-tag type="info" v-show="patient.sickArea">{{ patient.sickArea }}</el-tag>
                </div>
                <div class="item-inline" v-if="item.fields['studyDate'] === true">
                  <span>计划手术时间:</span>
                  <el-tag type="info" v-show="patient.studyDate">{{ patient.studyDate }}</el-tag>
                </div>
              </div>
            </template>
            <div class="time-stamp" v-if="timeStamp">
              <el-steps direction="vertical" :active="10">
                <el-step title="患者入急症室时间">
                  <template slot="description">
                    <div>
                      <el-button type="text" style="padding: 0">
                        <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                          <p>{{ patient.admissionTime }}</p>
                        </div>
                      </el-button>
                    </div>
                  </template>
                </el-step>
                <el-step title="介入医生到达时间">
                  <template slot="description">
                    <div>
                      <el-button type="text" style="padding: 0">
                        <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                          <p>{{ patient.interveneDoctorTime }}</p>
                        </div>
                      </el-button>
                    </div>
                  </template>
                </el-step>
                <el-step title="介入护士到达时间">
                  <template slot="description">
                    <div>
                      <el-button type="text" style="padding: 0">
                        <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                          <p>{{ patient.interveneNurserTime }}</p>
                        </div>
                      </el-button>
                    </div>
                  </template>
                </el-step>
                <el-step title="入导管室时间">
                  <template slot="description">
                    <div>
                      <el-button type="text" style="padding: 0">
                        <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                          <p>{{ patient.enterDate }}</p>
                        </div>
                      </el-button>
                    </div>
                  </template>
                </el-step>
                <el-step title="麻醉开始时间">
                  <template slot="description">
                    <div>
                      <el-button type="text" style="padding: 0">
                        <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                          <p>{{ patient.anaesthesiaStartDate }}</p>
                        </div>
                      </el-button>
                    </div>
                  </template>
                </el-step>
                <el-step title="手术开始时间">
                  <template slot="description">
                    <div>
                      <el-button type="text" style="padding: 0">
                        <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                          <p>{{ patient.studyStartDate }}</p>
                        </div>
                      </el-button>
                    </div>
                  </template>
                </el-step>

                <el-step title="手术结束时间">
                  <template slot="description">
                    <div>
                      <el-button type="text" style="padding: 0">
                        <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                          <p>{{ patient.studyEndDate }}</p>
                        </div>
                      </el-button>
                    </div>
                  </template>
                </el-step>
                <el-step title="麻醉结束时间">
                  <template slot="description">
                    <div>
                      <el-button type="text" style="padding: 0">
                        <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                          <p>{{ patient.anaesthesiaEndDate }}</p>
                        </div>
                      </el-button>
                    </div>
                  </template>
                </el-step>
                <el-step title="出导管室时间">
                  <template slot="description">
                    <div>
                      <el-button type="text" style="padding: 0">
                        <div :class="isEdit ? 'timeDiv' : 'timeInput'">
                          <p>{{ patient.leaveDate }}</p>
                        </div>
                      </el-button>
                    </div>
                  </template>
                </el-step>
                <el-step title="报告完成时间">
                  <template slot="description">
                    <el-button type="text" style="padding: 0">
                      <p>{{ patient.reportFinishDate }}</p>
                    </el-button>
                  </template>
                </el-step>
              </el-steps>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<style>
.my-label {
  min-width: 100px;
  height: 30px;
  line-height: 30px;
}
</style>
<script>
import deepClone from '../../../plugins/clone'
import CmSelect from '../../../components/CmSelect.vue'
import { PDF_HOST } from '@/utils/globalVariable'
export default {
  components: {
    CmSelect
  },
  data() {
    return {
      isEdit: true,
      loading: false,
      historyNote_status: true,
      patientStemi: '',
      historyNote: '',
      columnNum: 4,
      checkList: [],
      patient: {
        patientsHisNo: '', //检查号
        fileNumber: '', // 库号
        hisNo: '', //医院HIS流水号（住院号）
        patientsName: '', //患者姓名
        patientsAge: '', //患者年龄
        patientsSex: '', //患者性别
        lab: '', //手术室
        physician: '', //医生列表
        tech: '', //技师列表
        nurse: '', //护士列表
        examineDoctor: '', //主刀医生
        assistantDoctor1: '', //一助
        assistantDoctor2: '', //二助
        assistantDoctor3: '', //三助
        anesthesiaDoctor: '', //麻醉医生列表
        scrubNurse: '', //洗手护士
        tour: '', //巡回
        examineName: '', //手术名称
        examineType: '', //手术类型
        anesthesiaMethod: '', //麻醉方式
        anesthesiaConsultation: '', //麻醉会诊
        stemi: '', //Stemi
        interveneDoctorTime: '', //介入医生到达时间  yyyy-MM-dd HH:mm:ss
        interveneNurserTime: '', //介入护士到达时间  yyyy-MM-dd HH:mm:ss
        admissionTime: '', //患者入急诊室时间 yyyy-MM-dd HH:mm:ss
        admissionType: '', //入院方式
        enterDate: '', //入导管室时间 yyyy-MM-dd HH:mm
        leaveDate: '', //出导管室时间 yyyy-MM-dd HH:mm
        studyApplyTime: '', //手术申请时间 yyyy-MM-dd HH:mm  (科室申请手术时的日志时间, 这时候填写拟手术日期)
        planStudyDate: '', //拟手术日期 yyyy-MM-dd HH:mm
        studyDate: '', //手术日期 yyyy-MM-dd HH:mm
        studyStartDate: '', //手术开始时间 yyyy-MM-dd HH:mm
        studyEndDate: '', //手术结束时间 yyyy-MM-dd HH:mm
        anaesthesiaStartDate: '', //麻醉开始时间
        anaesthesiaEndDate: '', //麻醉结束时间
        planStudyDuration: '', //手术预计时长(单位min)
        isFirstOperation: '', //是否首次手术
        reOperationReason: '', //再次手术原因
        importantItem: '', //手术重要事项
        operateNumberTitle: '', //手术序号(台次)
        hbsag: '', //术前四项 乙肝
        hcv: '', //术前四项 丙肝
        hiv: '', //术前四项 艾滋
        rpr: '', //术前四项 梅毒
        bedNo: '', //床号
        ward: '', //病区(科室)
        preoperativeDiagnosis: '', //临床诊断
        clinicDiagnose: '', //临床诊断
        height: '', //身高
        weight: '', //体重
        marry: '', //婚姻状况
        insuranceType: '', //医保类型
        admissionDate: '', //住院日期 yyyy-MM-dd HH:mm
        dischargeDate: '', //出院日期 yyyy-MM-dd HH:mm
        isDyslipidemia: '', //血脂异常(是 /否)
        isHypertension: '', //高血压(是/否)
        isDiabetes: '', //糖尿病(是/否)
        isHeartDisease: '', //心脏病(是/否)
        medicalHistoryNote: '', //病史其他描述
        drugAllergyNote: '', //药物过敏内容
        complication: '', //合并症
        cutType: '', //切口类型
        physicianId: '', //医生id列表
        nurseId: '', //护士id列表
        examineDoctorId: '', //主刀医生id
        assistantDoctor1Id: '', //一助id
        assistantDoctor2Id: '', //二助id
        assistantDoctor3Id: '', //三助id
        anesthesiaDoctorId: '', //麻醉医生id列表
        scrubNurseId: '', //洗手护士id
        patientsID: '', //病案号
        patientsDOB: '', //患者出生日期 yyyy-MM-dd
        patientsPhone: '', //患者本人电话
        patientsAddress: '', //家庭住址
        patientsCertificate: '', //证件号
        idType: '', //证件类型
        nation: '', //国家
        contactName: '', //患者联系人姓名
        contactPhone: '', //患者联系人手机号
        province: '', //省
        city: '', //市
        county: '', //县
        ethnicity: '', //民族
        eduLevel: '' //文化程度
      },

      // 是否可编辑
      isReadOnly: false,
      patientInfo: {},
      scrollTrigger: false, //默认初始值
      showList: [],
      timeStamp: false,
      offSideShow: false
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.uuid) {
          this.renderData(val)
        }
      },
      immediate: true
    }
  },
  computed: {},
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    this.operationId = this.$route.query.operationId
  },

  mounted() {
    this.windowWidth()
    this.getQueryEnable()
    this.$bus.$emit('getUserFollowInfo')
  },
  methods: {
    calculateAgeFromDays(days) {
      if (days) {
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()

        const birthDate = new Date(currentDate.getTime() - days * 24 * 60 * 60 * 1000)
        const birthYear = birthDate.getFullYear()

        let age = currentYear - birthYear

        // 检查生日是否已经过了，如果还没过，则减去一年
        if (currentDate < new Date(birthDate.setFullYear(currentYear))) {
          age--
        }

        if (age < 1) {
          return days + '天'
        } else if (age < 2) {
          const remainingDays = Math.floor((currentDate - birthDate) / (24 * 60 * 60 * 1000))
          return '1年零' + remainingDays + '天'
        } else {
          return age + '岁'
        }
      } else {
        return ''
      }
    },
    getQueryEnable() {
      this.$api.get(`/v1/webconsole/follow/baseline/${this.operationId}`).then((res) => {
        if (res.data && res.data.data) {
          this.showList = res.data.data.config
          for (let i = 0; i < this.showList.length; i++) {
            const element = this.showList[i]
            if (element.group === 'OFFSIDE') {
              if (element.fields !== null) {
                this.offSideShow = true
                this.timeStamp = element.fields['timeStamp']
              }
            }
          }
        }
      })
    },
    scrollToTop() {
      if (this.scrollTrigger) {
        return
      }

      const container = document.querySelector('.body') // 选择合适的父元素
      if (container.scrollTop > 0) {
        let scrollTop = container.scrollTop
        let steep = scrollTop / 2000
        let timer = setInterval(() => {
          this.scrollTrigger = true
          // 滚动的速度逐渐变快，第一次走2000/1，然后减去已走的距离，下一次用剩下的距离再减去步进值，步进值也是不断变化，这样速度会越来越快
          scrollTop -= steep
          // 步进值不改变的话会匀速缓慢上滑，不断增加步进值上滑的距离增加，视觉效果速度变快
          steep += 20
          if (scrollTop <= 0) {
            clearInterval(timer)
            this.scrollTrigger = false
          }
          container.scrollTop = scrollTop
        }, 20)
      }
    },
    onSelected(data) {
      this.patientInfo.province = data.province.value
      this.patientInfo.city = data.city.value
      this.patientInfo.county = data.area.value
      this.patientInfo.provinceCode = data.province.code
      this.patientInfo.cityCode = data.city.code
      this.patientInfo.countyCode = data.area.code
    },

    synchronousClick() {
      if (this.patientInfo.patientsID) {
        this.$api
          .get(
            `/v1/webconsole/patientCenter/patient/his/${this.patientInfo.patientsID}/${this.patientInfo.uuid}`
          )
          .then((res) => {
            if (res.data.status === 200 && res.data.data !== null) {
              this.patientInfo.patientsName =
                this.patientInfo.patientsName || res.data.data.patientsName
              this.patientInfo.idType = res.data.data.idType || this.patientInfo.idType
              this.patientInfo.eduLevel = res.data.data.eduLevel || this.patientInfo.eduLevel
              this.patientInfo.fileNumber = res.data.data.fileNumber || this.patientInfo.fileNumber
              this.patientInfo.patientsCertificate =
                res.data.data.patientsCertificate || this.patientInfo.patientsCertificate
              this.patientInfo.ethnicity = res.data.data.ethnicity || this.patientInfo.ethnicity
              this.patientInfo.nation = res.data.data.nation || this.patientInfo.nation
              this.patientInfo.province = res.data.data.province || this.patientInfo.province
              this.patientInfo.city = res.data.data.city || this.patientInfo.city
              this.patientInfo.county = res.data.data.county || this.patientInfo.county
              this.patientInfo.patientsAddress =
                res.data.data.patientsAddress || this.patientInfo.patientsAddress
              this.patientInfo.patientsPhone =
                res.data.data.patientsPhone || this.patientInfo.patientsPhone
              this.patientInfo.patientsDOB =
                res.data.data.patientsDOB || this.patientInfo.patientsDOB
              this.patientInfo.patientsAge =
                Math.floor(res.data.data.ageDays / 365) ||
                res.data.data.patientsAge ||
                this.patientInfo.patientsAge
              this.patientInfo.patientsSex =
                res.data.data.patientsSex || this.patientInfo.patientsSex
              this.patientInfo.height = res.data.data.patientsHeight || this.patientInfo.height
              this.patientInfo.weight = res.data.data.patientsWeight || this.patientInfo.weight
              this.patientInfo.ward = res.data.data.patientsWard || this.patientInfo.ward
              this.patientInfo.bedNo = res.data.data.patientsBedNo || this.patientInfo.bedNo
              this.patientInfo.admissionDate =
                res.data.data.admissionDate || this.patientInfo.admissionDate
              this.patientInfo.dischargeDate =
                res.data.data.dischargeDate || this.patientInfo.dischargeDate
            } else {
              this.$message.error(res.data.msg)
            }
          })
      } else {
        this.$message.error('请输入患者病案号')
      }
    },
    editClick() {
      this.isEdit = false
      this.anesthesiaDoctorId = deepClone(this.patientInfo.anesthesiaDoctorId?.split('|'))
      if (
        !this.patient.isDyslipidemia &&
        !this.patient.isHypertension &&
        !this.patient.isDiabetes &&
        !this.patient.isHeartDisease &&
        !this.patient.medicalHistoryNote
      ) {
        this.historyNote = '无'
      } else this.historyNote = '有'
    },
    closeClick() {
      this.isEdit = true
      if (this.patient) {
        this.patientInfo = deepClone(this.patient)
      }
    },
    changeStemi(val) {
      if (val == '是') {
        this.patientInfo.stemi = 1
      } else if (val == '否') {
        this.patientInfo.stemi = 0
      }
    },
    changeHistoryNote(val) {
      if (val == 'wu') {
        this.patientInfo.isDyslipidemia = ''
        this.patientInfo.isHypertension = ''
        this.patientInfo.isDiabetes = ''
        this.patientInfo.isHeartDisease = ''
        this.patientInfo.medicalHistoryNote = ''
        this.historyNote_status = true
        this.checkList = []
        this.historyNote = '无'
      } else {
        this.historyNote = '有'
      }
    },
    handleCheckedChange(val) {
      if (val.includes('血脂异常')) {
        this.patientInfo.isDyslipidemia = '是'
      }
      if (val.includes('高血压')) {
        this.patientInfo.isHypertension = '是'
      }
      if (val.includes('糖尿病')) {
        this.patientInfo.isDiabetes = '是'
      }
      if (val.includes('心脏病')) {
        this.patientInfo.isHeartDisease = '是'
      }
      if (val.includes('其他')) {
        this.historyNote_status = false
      } else {
        this.historyNote_status = true
        this.patientInfo.medicalHistoryNote = ''
      }
    },
    windowWidth() {
      var w = document.documentElement.clientWidth //获取当前窗口可视操作区域高度
      if (w < 1900 && w > 1410) {
        this.columnNum = 3
      } else if (w < 1410) {
        this.columnNum = 2
      } else {
        this.columnNum = 4
      }
    },
    followPlanDownload() {
      window.open(
        PDF_HOST +
          `/api/v1/webconsole/follow/export/follow/plan/${this.operationId}/${this.$route.query.moduleCode}`,
        '_blank'
      )
    },
    Save() {
      this.isEdit = true
      delete this.patientInfo.examineNameList
      delete this.patientInfo.doctorIdList
      delete this.patientInfo.doctorNameList
      delete this.patientInfo.nurseIdList
      delete this.patientInfo.nurseNameList
      delete this.patientInfo.tourIdList
      delete this.patientInfo.tourNameList
      delete this.patientInfo.techIdList
      delete this.patientInfo.techNameList
      this.$api
        .post(`/v1/webconsole/study/operation/save/${this.operationId}`, {
          ...this.patientInfo
        })
        .then(
          (res) => {
            this.loading = false
            this.patient = res.data.data
            this.$store.dispatch('setCurrentPatientAct', this.patient.uuid, false)
            this.scrollToTop()
            this.$bus.$emit('getFollowTimeAxis', 'hello')
            return this.$MessageAi.Message({
              type: 'success',
              icon: 'el-icon-success',
              message: '编辑成功'
            })
            t
          },
          () => {
            this.loading = false
            return this.$message.error('保存出错')
          }
        )
    },
    saveClick() {
      if (
        !document.getElementById('patientName').value ||
        !this.patientInfo.patientsSex ||
        !this.patientInfo.patientsCertificate ||
        !this.patientInfo.patientsPhone
      ) {
        this.$message({
          message: '请输入姓名，性别，证件号，本人电话',
          type: 'warning'
        })
      } else {
        this.$confirm('是否保存编辑信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.loading = true

            this.Save()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消保存'
            })
          })
      }
    },
    renderData(obj) {
      this.patient.patientsID = obj.patientsID
      this.patient.patientsName = obj.patientsName
      this.patient.patientsSex = obj.patientsSex
      this.patient.lab = obj.lab
      this.patient.patientsHisNo = obj.patientsHisNo
      this.patient.studyDate = obj.studyDate == null ? obj.studyDate : obj.studyDate.substr(0, 16)
      this.patient.admissionTime =
        obj.admissionTime == null ? obj.admissionTime : obj.admissionTime.substr(0, 16)
      this.patient.interveneDoctorTime =
        obj.interveneDoctorTime == null
          ? obj.interveneDoctorTime
          : obj.interveneDoctorTime.substr(0, 16)
      this.patient.interveneNurserTime =
        obj.interveneNurserTime == null
          ? obj.interveneNurserTime
          : obj.interveneNurserTime.substr(0, 16)
      this.patient.studyStartDate =
        obj.studyStartDate == null ? obj.studyStartDate : obj.studyStartDate.substr(0, 16)
      this.patient.studyEndDate =
        obj.studyEndDate == null ? obj.studyEndDate : obj.studyEndDate.substr(0, 16)
      this.patient.enterDate = obj.enterDate == null ? obj.enterDate : obj.enterDate.substr(0, 16)
      this.patient.leaveDate = obj.leaveDate == null ? obj.leaveDate : obj.leaveDate.substr(0, 16)
      this.patient = { ...this.patient, ...obj }
      if (this.patient.stemi == 1) {
        this.patientStemi = '是'
      } else if (this.patient.stemi == 0) {
        this.patientStemi = '否'
      }
      if (
        this.patient.isDyslipidemia ||
        this.patient.isHypertension ||
        this.patient.isDiabetes ||
        this.patient.isHeartDisease ||
        this.patient.medicalHistoryNote
      ) {
        this.historyNote = '有'
        if (this.patient.isDyslipidemia == '是') {
          this.checkList.push('血脂异常')
        }
        if (this.patient.isHypertension == '是') {
          this.checkList.push('高血压')
        }
        if (this.patient.isDiabetes == '是') {
          this.checkList.push('糖尿病')
        }
        if (this.patient.isHeartDisease == '是') {
          this.checkList.push('心脏病')
        }
        if (this.patient.medicalHistoryNote) {
          this.checkList.push('其他')
          this.historyNote_status = false
        }
      }
      if (this.patient) this.patientInfo = deepClone(this.patient)
    }
  }
}
</script>

<style lang="less" scoped>
.input-form-item-content {
  width: 180px;
}
@media screen and (min-width: 1400px) and (max-width: 1490px) {
  .input-form-item-content {
    width: 120px;
  }
}
.input-form-item-textContent {
  width: 400px;
}
@media screen and (min-width: 1400px) and (max-width: 1490px) {
  .input-form-item-textContent {
    width: 300px;
  }
}
.data-picker {
  height: 25px;
  line-height: 25px;
  width: 180px;
  .el-date-editor.el-input {
    width: 180px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1490px) {
  .data-picker {
    width: 120px;
    .el-date-editor.el-input {
      width: 120px;
    }
  }
}
/deep/ .distpicker-address-wrapper select {
  padding: 0;
  height: 28px;
  font-size: 14px;
  line-height: 28px;
}

.data-form {
  width: 100%;
}
.data-form-item {
  margin-bottom: 15px;
  margin-left: 10px;
}

.time-stamp {
  min-height: 300px;
}

/deep/ .el-descriptions-item__content {
  display: flex;
  align-items: center;
}
.pwrap {
  display: flex;
  align-items: center;
  .info-address {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
}

.Base {
  .crt-content {
    justify-content: space-between;
  }
  .el-divider__text.is-left {
    font-size: 18px;
  }
  .el-descriptions {
    margin: 10px 25px;
  }
  .body {
    flex: 1;
    overflow: auto;
  }
  .body::-webkit-scrollbar {
    width: 12px;
  }
  .body::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  .body::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
  }
}
.item-inline {
  margin: 15px;
  display: flex;
}
.item-inline > span {
  flex: 0 0 120px;
  height: 25px;
  line-height: 25px;
}
@media screen and (max-width: 1400px) {
  .item-inline > span {
    flex: 0 0 105px;
  }
}
.input-form {
  display: flex;
  /deep/ span {
    color: #707070;
  }
}
.input-data-left {
  flex-grow: 5;
  flex-shrink: 5;
  padding-right: 10px;
  /deep/ span {
    color: #707070;
  }
  .el-divider__text.is-left {
    font-size: 18px;
  }
  .el-descriptions {
    margin: 10px 25px;
  }
}
.input-data-right {
  flex: 0 1 400px;
  .el-divider__text.is-left {
    font-size: 18px;
  }
}
@media screen and (max-width: 1400px) {
  .input-data-right {
    flex: 0 1 360px;
    height: 92vh;
  }
}
</style>
